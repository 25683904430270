import { Contract } from "./Contract";
import baseApi from "./abis/Pool";
export class Pool extends Contract {
  constructor(address) {
    super(address, baseApi);
  }

  async getRewardByAction({
    user,
    amount,
    usdtAmount,
    deadBlock,
    nonce,
    action,
    v,
    r,
    s,
  }) {
    return await this.sendContract("getRewardByAction", [
      user,
      amount,
      usdtAmount,
      deadBlock,
      nonce,
      action,
      v,
      r,
      s,
    ]);
  }

  async unlock(token, user, amount) {
    console.log(token, user, this.toToken(amount, 18));
    return await this.sendContract("unlock", [
      token,
      user,
      this.toToken(amount, 18)
    ]);
  }

  async unlockUsdt(token, user, amount) {
    console.log(token, user, this.toToken(amount, 18));
    return await this.sendContract("unlock", [
      token,
      user,
      this.toToken(amount, 6)
    ]);
  }

  

  async changeSafeUsdtAmount(amount) {
    return await this.sendContract("changeSafeUsdtAmount", [
      this.toToken(amount, 6),
    ]);
  }

  async changeSafeArcAmount(amount) {
    return await this.sendContract("changeSafeArcAmount", [
      this.toToken(amount, 18),
    ]);
  }

  async changeSafeArbAmount(amount) {
    return await this.sendContract("changeSafeArbAmount", [
      this.toToken(amount, 18),
    ]);
  }

  async safeArcAmount() {
    const safeAmount = await this.callContract("safeArcAmount", []);
    return this.fromToken(safeAmount.toString(), 18);
  }

  async safeUsdtAmount() {
    const safeAmount = await this.callContract("safeUsdtAmount", []);
    return this.fromToken(safeAmount.toString(), 6);
  }

  async safeArbAmount() {
    const safeAmount = await this.callContract("safeArbAmount", []);
    return this.fromToken(safeAmount.toString(), 18);
  }

  async owner() {
    return await this.callContract("owner", []);
  }
}
