

import Wallet, { Client as WalletClent } from "./lib/Wallet";

/**
 * 合约工具入口
 */

// 导出工具库
export * from './lib/transaction'
export * as utils from './lib/utils'
export * from './lib/constants'
export * from './lib/eip712'
export * from './lib/error'
export * from './lib/Loading'
export const Client = WalletClent

// 合约按模块导出
export { Contract } from './Contract'
export { ERC20 } from './ERC20'
export { ERC721 } from './ERC721'
export { Router } from './Router'
export { Pair } from './Pair'
export { NodeShop } from './NodeShop'
export { Pool } from './Pool'
export {InvestShop} from './InvestShop'
export { EthPool } from './EthPool'
export {InvestMoreShop} from './InvestMoreShop'
export {LpInvestShop} from './LpInvestShop'


// 导出主库
export default Wallet