const networks = [
  {
    id: 'mainnet',
    name: '以太坊 Ethereum 主网络',
    symbol: 'ETH',
    symbolName: 'ETH Coin',
    decimals: 18,
    chainId: '0x1',
    url: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    explorer: 'https://etherscan.io'
  },
  {
    id: 'ropsten',
    name: 'Ropsten 测试网络',
    symbol: 'ETH',
    symbolName: 'ETH Coin',
    decimals: 18,
    chainId: '0x3',
    url: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    explorer: 'https://ropsten.etherscan.io'
  },
  {
    id: 'testnet',
    name: 'BSC Testnet',
    symbol: 'BNB',
    symbolName: 'Binance Coin',
    decimals: 18,
    chainId: '0x61',
    networkId: 97,
    url: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    explorer: 'https://testnet.bscscan.com'
  },
  {
    id: 'mainnet',
    name: 'BSC Mainnet',
    symbol: 'BNB',
    symbolName: 'Binance Coin',
    decimals: 18,
    chainId: '0x38',
    networkId: 56,
    url: 'https://bsc-dataseed.binance.org',
    explorer: 'https://bscscan.com'
  },

  {
    id: 'mainnet',
    name: 'ARB Mainnet',
    symbol: 'ETH',
    symbolName: 'ETH Coin',
    decimals: 18,
    chainId: '0xa4b1',
    networkId: 42161,
    url: 'https://arb1.arbitrum.io/rpc',
    explorer: 'https://arbiscan.io/'
  },

  
  {
    id: 'testnet',
    name: 'HECO Testnet',
    symbol: 'HT',
    symbolName: 'Heco Coin',
    decimals: 18,
    chainId: '0x100',
    networkId: 256,
    // url: 'https://http-testnet.huobichain.com',
    url: 'https://http-testnet.hecochain.com',
    explorer: 'https://testnet.hecoinfo.com'
  },
  {
    id: 'mainnet',
    name: 'HECO Mainnet',
    symbol: 'HT',
    symbolName: 'Heco Coin',
    decimals: 18,
    chainId: '0x80',
    networkId: 128,
    // url: 'https://http-mainnet-node.huobichain.com',
    url: 'https://http-mainnet.hecochain.com',
    explorer: 'https://hecoinfo.com'
  },
  {
    id: 'localhost',
    name: 'Localhost',
    symbol: 'ETH',
    symbolName: 'ETH Coin',
    decimals: 18,
    chainId: '0x7a69',
    networkId: 31337,
    url: 'http://127.0.0.1:8545',
    explorer: 'http://127.0.0.1:8545'
  }
]

export default networks
