
import BigNumber from 'bignumber.js'

export const fromRpcSig = (signature) => {
    const sig = signature.slice(0, 2) === '0x'
        ? Buffer.from(signature.slice(2), 'hex')
        : Buffer.from(signature, 'hex');
    // NOTE: with potential introduction of chainId this might need to be updated
    if (sig.length !== 65) {
        throw new Error('Invalid signature length');
    }

    let v = sig[64];
    // support both versions of `eth_sign` responses
    if (v < 27) {
        v += 27;
    }
    return {
        v,
        r: '0x' + sig.slice(0, 32).toString('hex'),
        s: '0x' + sig.slice(32, 64).toString('hex'),
    };
}

/**
* 转换币种基本单位
* @param {Number} num 转换数值
* @param {Number} decimals 币种精度
*/
export const toToken = (num, decimals = 18) => {
    return new BigNumber(num).multipliedBy(new BigNumber(Math.pow(10, decimals))).toFormat().replaceAll(',', '')
}

/**
 * 币种基本单位转换为数值
 * @param {Number} num 转换数值
 * @param {Number} decimals 币种精度
 */
export const fromToken = (num, decimals = 18) => {
    return new BigNumber(num).dividedBy(new BigNumber(Math.pow(10, decimals))).toFormat().replaceAll(',', '')
}

/**
 * 缩短以太坊地址
 * @param {*} address 
 * @param {*} width 
 * @returns 
 */
export const ellipseAddress = (address = '', width = 10) => {
    if (!address) {
        return ''
    }
    return `${address.slice(0, width)}***${address.slice(-width)}`
}

/**
 * 将js科学计数法修改为普通数字
 * 1e3 => 0.001
 * 
 * @param {*} num 数字
 * @param {*} digits 精度
 * @returns 
 */
export const toNonExponential = (num, digits = 6) => {
    const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    const decimals = (m[1] || '').length - m[2]
    const numberStr = num.toFixed(Math.max(0, decimals > digits ? digits : decimals));
    if (decimals <= digits) {
        return numberStr
    }
    // 目标精度大于给出数的精度，可能出现再次出给出数后小数点
    // 正则匹配小数点最末尾的0
    const temp = /^(\d{1,}(?:,\d{3})*\.(?:0*[1-9]+)?)(0*)?$/.exec(num.toFixed(digits));
    if (temp) {
        if (temp[1][temp[1].length - 1] === '.') {
            return temp[1].substring(0, temp[1].length - 1)
        }
        return temp[1];
    } else {
        return num.toFixed(digits)
    }
}

export const toBalance = (num, digits = 2) => {
    if (isNaN(Number(num))) {
        console.error(`${num} is NAN`)
        return 0
    }
    return Number(Number(num).toFixed(digits))
}