
import { Contract } from './Contract'
import baseApi from './abis/NodeShop'
export class NodeShop extends Contract {

  constructor(address) {
    super(address, baseApi)
  }


  async buyNode({ user, usdtAmount, arcAmount, deadline,nodeType,v,r, s}) {
    return await this.sendContract(
      'buyNode',
      [user, usdtAmount, arcAmount, deadline,nodeType,v,r, s]
    )
  }

  async tokenIn() {
    return await this.callContract(
      'tokenIn',
      []
    )
  }
  
  async setTokenIn(address) {
    return await this.sendContract(
      'setTokenIn',
      [address]
    )
  }

  
}