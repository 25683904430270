

import { ethers } from 'ethers'
import { fromToken, toToken } from './lib/utils'
import { Client } from './lib/Wallet'
import { sendTransaction } from './lib/transaction'
import { ZERO_ADDRESS } from './lib/constants'

export class Contract {

  MAX_APPROVE = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

  abi = null

  constructor(address, abi) {
    this._walletClient = Client
    this.abi = abi
    // 动态调用
    this.contract = new ethers.Contract(address, abi, this._walletClient.provider)
    // 静态调用
    this.staticContract = new ethers.Contract(address, abi, this._walletClient.callProvider)
  }

  get address() {
    return this.contract.address
  }

  get currentAddress() {
    return this._walletClient.address
  }

  async syncCurrentAddress() {
    if (this.currentAddress === ZERO_ADDRESS) {
      // 地址还没初始化，进行初始化获取
      await Client.init()
    }
    return this.currentAddress;
  }

  toToken(num, decimals) {
    return toToken(num, decimals)
  }

  fromToken(num, decimals) {
    return fromToken(num, decimals)
  }

  /**
   * 调用合约call
   */
  async callContract(method, parameters = []) {
    return await this.staticContract.callStatic[method](...parameters, { from: this.currentAddress })
  }

  /**
   * 调用合约send
   */
  async sendContract(method, parameters = [], value = 0) {
    const txParams = await this.contract.populateTransaction[method](...parameters, {})
    return await sendTransaction(txParams, value)
  }


}