import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '',
		component: () => import('@/views/index/index.vue'),
		meta: {
			showTabs: true,
			auth: false
		}
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('@/views/index/index.vue'),
		meta: {
			showTabs: true,
			auth: false
		}
	},
	{
		path: '/pledge',
		name: 'pledge',
		component: () => import('@/views/pledge/index.vue'),
		meta: {
			showTabs: true,
			auth: false
		}
	},
	{
		path: '/lp',
		name: 'lp',
		component: () => import('@/views/lp/index.vue'),
		meta: {
			showTabs: true,
			auth: false
		}
	},
	{
		path: '/pool',
		name: 'pool',
		component: () => import('@/views/pool/index.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/my',
		name: 'my',
		component: () => import('@/views/my/index.vue'),
		meta: {
			showTabs: true,
			auth: false
		}
	},
	{
		path: '/myNode',
		name: 'myNode',
		component: () => import('@/views/index/myNode.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/withdraw',
		name: 'withdraw',
		component: () => import('@/views/my/withdraw.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/record',
		name: 'record',
		component: () => import('@/views/my/record.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/invite',
		name: 'invite',
		component: () => import('@/views/my/invite.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/team',
		name: 'team',
		component: () => import('@/views/my/team.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/blindBox',
		name: 'blindBox',
		component: () => import('@/views/blindBox/index.vue'),
		meta: {
			showTabs: true,
			auth: false
		}
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/home.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('@/views/test.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
	{
		path: '/config',
		name: 'config',
		component: () => import('@/views/config/config.vue'),
		meta: {
			showTabs: false,
			auth: false
		}
	},
]


const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
  const { meta: { showTabs } } = to
  const isShow = !!showTabs
  if (store.state.showTabs !== isShow) {
    //修改底部tab栏显示或隐藏
    store.commit('toggleTabs', isShow)
  }
  next()
})

export default router
