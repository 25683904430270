
// import router from '../router'

import { accountInfo } from '@/api/account'


export default {
	async getUserInfo({ commit }) {
        const { err, res } = await accountInfo()
        if (err === null) {
            commit('updateUser', res)
        }
    }
};