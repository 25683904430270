import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

// 引入amfe-flexible
import 'amfe-flexible/index'

// 国际化
import i18n from './language/index.js'
Vue.prototype._i18n = i18n

// 注册dapp
import dapp from '@/utils/dapp'
Vue.prototype.$dapp = dapp

Vue.config.productionTip = false


// 全局组件
import myHeader from './views/components/myHeader.vue'
Vue.component('myHeader',myHeader)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
