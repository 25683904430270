
import networks from './networks'
import { utils, providers } from 'ethers'
import { ZERO_ADDRESS } from './constants'
import { getSignTypedData } from './eip712'
import { loading } from './Loading'

export default class WalletClient {
  constructor(connect, close) {
    this.name = "UnkonwWallet"
    this._targetChainId = undefined
    this._chainId = undefined
    this._currentAccount = undefined
    this._ethereum = undefined
    // 动态调用
    this._provider = undefined
    // 静态调用
    this._callProvider = undefined
    // 连接钱包
    this._connect = connect
    // 关闭连接
    this._close = close
    this._onConnecting = false
    this._enabled = false
    this._onEnabled = undefined
    this._waitingEnabled = []
  }

  get provider() {
    return this._provider
  }

  get callProvider() {
    return this._callProvider
  }

  set ethereum(ethereum) {
    this._ethereum = ethereum
    this._provider = new providers.Web3Provider(this._ethereum)
    this._callProvider = this._provider
    this.getName()
  }

  

  set chainId(chainId) {
    const network = networks.find(n => n.chainId === chainId)
    if (network === null) {
      throw Error("CHAIN_ID 配置错误")
    }
    // 静态调用
    if (!this._callProvider) {
      this._callProvider = new providers.JsonRpcProvider({ url: network.url })
    }
    this._targetChainId = chainId
  }

  getName() {
    if (this._ethereum.isMetaMask) {
      this.name = 'MetaMask'
    } else if (this._ethereum.isImToken) {
      this.name = "ImToken"
    } else if (this._ethereum.isCoinbaseWallet) {
      this.name = "CoinbaseWallet"
    } else if (this._ethereum.isTrust) {
      this.name = "TrustWallet"
    } else {
      this.name = "UnkonwWallet"
    }
  }

  get chainId() { return this._chainId }

  get address() {
    //return "0x29bEcFBdEf0a447282E03444765A96d2D7d2a9bc"
    if (this._currentAccount) {
      return this._currentAccount.address
    }
    return ZERO_ADDRESS
  }

  get isEnabled() { return this._enabled }

  async onConnect() {
    try {
      if (this._onConnecting) {
        // 可能并发重入，loading限制每次只能进来一个
        await this._checkNetwork()
        this._setEnabled()
        return;
      }
      this._onConnecting = true
      await this._connect()
      await this._checkNetwork()
      await this.subscribeProvider();
      await this._initCurrentAddress()
    } catch (err) {
      this._onConnecting = false
      // 链接失败，需要清空等待连接成功的队列
      this._waitingEnabled = []
      loading.hide()
      throw err
    }
    // 连接成功
    this._onEnabled && this._onEnabled({ address: this.address })
    this._enabled = true
    loading.hide()
  }

  async init() {
    await this._checkInit()
    loading.hide()
  }

  async _checkInit() {
    if (!this.isEnabled || !this._ethereum || this._chainId !== this._targetChainId) {
      // 未连接钱包
      loading.hide()
      await this.onConnect()
      if (!this.isEnabled) {
        await new Promise((reslove, reject) => {
          // 推入待等待队列
          this._waitingEnabled.push(() => reslove())
        })
      }
      loading.show()
    }
    if (!this._currentAccount) {
      await this._initCurrentAddress()
    }
  }

  async _initCurrentAddress() {
    const address = await this._provider.getSigner().getAddress()
    this._currentAccount = { address }
    this._onAccountChanged && this._onAccountChanged(this.address)
    // const accounts = await this._ethereum.request({ method: 'eth_requestAccounts' })
    // this._currentAccount = { address: utils.getAddress(accounts[0]) }
    this._setEnabled()
  }

  onEnabled(callback) {
    this._onEnabled = callback
    return () => this._onEnabled = undefined
  }

  _setEnabled() {
    this._waitingEnabled.forEach(reslove => reslove());
    this._waitingEnabled = []
  }

  onNetworkChanged(callback) {
    this._onNetworkChanged = callback
    return () => this._onNetworkChanged = undefined
  }

  onAccountChanged(callback) {
    this._onAccountChanged = callback
    return () => this._onAccountChanged = undefined
  }

  dispose() {
    this._close();
    this._ethereum.removeAllListeners('chainChanged')
    this._ethereum.removeAllListeners('accountsChanged')
    this._currentAccount = undefined
    this._onConnecting = false
    this._enabled = false
    loading.hide()
  }

  async subscribeProvider() {
    if (!this._ethereum.on) {
      return;
    }
    // Subscribe to provider connection
    this._ethereum.on("connect", (info) => {
      console.log('连接成功', info);
      this._initCurrentAddress()
    });

    //断开连接
    this._ethereum.on('disconnect', (info) => {
      this._currentAccount = undefined
      console.log('断开连接', info)
      this.dispose()
    });

    // Subscribe to chainId change
    this._ethereum.on("chainChanged", (chainId) => {
      console.log('chainChanged', chainId);
      this._chainId = chainId
      this._onNetworkChanged && this._onNetworkChanged(this._chainId)
    });

    //切换账号
    this._ethereum.on('accountsChanged', async (accounts) => {
      console.log('切换账号', accounts)
      if (Array.isArray(accounts) && accounts.length > 0) {
        this._currentAccount = { address: utils.getAddress(accounts[0]) }
        this._onAccountChanged && this._onAccountChanged(this.address)
      } else {
        this.dispose()
      }
    });
  }


  // 添加自定义代币到metamask
  async addToken({
    address,
    type = 'ERC20',
    symbol,
    decimals = 18,
    image = ''
  }) {
    if (!this._ethereum.isMetaMask) {
      return
    }
    return await this._ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type,
        options: {
          address,
          symbol,
          decimals,
          image
        }
      }
    })
  }

  async _checkNetwork() {
    if (!this._ethereum) {
      return
    }
    this._chainId = await this._ethereum.request({ method: 'eth_chainId' })
    if (this._chainId !== this._targetChainId && this.name === 'MetaMask') {
      loading.show()
      console.log(this._chainId ,this._targetChainId)
      if (await this.switchToEthereum(this._targetChainId)) {
        console.log('切换网络成功')
        await this._initCurrentAddress()
      }
      loading.show()
    }
  }

  /**
   * @description: 切换链ID
   * @param {*}
   * @return {*}
   */
  async switchToEthereum(chainId) {
    try {
      await this._ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }]
      })
      return true
    } catch (switchError) {
      console.log(switchError)
      if (switchError.code === 4902) {
        return await this.addNetwork(chainId)
      } else if (switchError.code === 4001) {
        throw Error(switchError.message)
      }
    }
    return false
  }

  /**
  * @description: 往matemask添加链
  * @param {*} chainId
  * @return {*}
  */
  async addNetwork(chainId) {
    try {
      const network = networks.find(n => n.chainId === chainId)
      if (!network) {
        throw Error('不支持的chainId' + chainId)
      }
      const params = [{
        'chainId': chainId, // 56 in decimal
        'chainName': network.name,
        'rpcUrls': [
          network.url
        ],
        'nativeCurrency': {
          'name': network.symbolName,
          'symbol': network.symbol,
          'decimals': network.decimals
        },
        'blockExplorerUrls': [
          network.explorer
        ]
      }]
      await this._ethereum.request({
        method: 'wallet_addEthereumChain',
        params
      })
      return true
    } catch (addError) {
      // handle "add" error
      console.log(addError)
      return false
    }
  }

  async signMessage(message) {
    await this._checkInit()
    return await this._ethereum.request({ method: 'eth_sign', params: [this.address, message] })
  }

  async signTypedData(typedData) {
    await this._checkInit()
    return await this._provider.getSigner()._signTypedData(
      ...getSignTypedData(typedData)
    )
  }

  async sendTransaction(txData) {
    await this._checkInit()
    txData.from = this.address
    return await this._ethereum.request({
      method: 'eth_sendTransaction',
      params: [txData],
    })
  }

  async getTransactionReceipt(tx) {
    return await this._ethereum.request({
      method: 'eth_getTransactionReceipt',
      params: [tx],
    })
  }

  async estimateGas(txData) {
    await this._checkInit()
    txData.from = this.address
    return await this._ethereum.request({
      method: 'eth_estimateGas',
      params: [txData],
    })
  }

  async getNewBlockNumber() {
    return await this._callProvider.getBlockNumber()
  }

  async getBalance(address){
    return await this._callProvider.getBalance(address)
  }
}