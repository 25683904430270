

class Loading {

    _maskDiv = undefined

    _loadingDiv = undefined

    _loadingSpan = undefined

    //获取滚动条距离上边顶部的距离
    getScrollTop() {
        var scrollTop = 0;
        if (document.documentElement && document.documentElement.scrollTop) {
            scrollTop = document.documentElement.scrollTop;
        } else if (document.body) {
            scrollTop = document.body.scrollTop;
        }
        return scrollTop;
    }
    //获取内部内容的总高度,
    getScrollHeight() {
        return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
    }
    //是获取可见内容的高度
    getHeight() {
        if (window.innerHeight != undefined) {
            return window.innerHeight;
        } else {
            var B = document.body, D = document.documentElement;
            return Math.min(D.clientHeight, B.clientHeight)
        }
    }
    //显示阴影
    showShadow() {
        if (!this._maskDiv) {
            var maskHeight = this.getScrollHeight() + "px";
            var shadowDiv = document.createElement("div");
            shadowDiv.innerHTML = "";
            shadowDiv.setAttribute('id', 'shadowDiv_MASK');
            shadowDiv.setAttribute('style', 'position:fixed; position: absolute; z-index: 999;left:0;top:0;display:block;width:100%;height:' + maskHeight + '; opacity:0.6;filter: alpha(opacity=60);-moz-opacity: 0.6; background:#000;');
            var body = document.getElementsByTagName("body")[0];
            body.appendChild(shadowDiv);
            this._maskDiv = document.getElementById('shadowDiv_MASK');
        }
        this._maskDiv.style.display = 'block'
    }
    //关闭阴影
    hideShadow() {
        if (this._maskDiv) {
            this._maskDiv.style.display = 'none'
        }
    }

    //显示Loading
    show(txt = 'Loading...') {
        this.showShadow();
        if (!this._loadingDiv) {
            var top = this.getScrollTop() + (this.getHeight() / 2) + "px";
            var loadingDiv = document.createElement("div");
            loadingDiv.setAttribute('id', 'loadingDiv');
            loadingDiv.setAttribute('style', 'padding:5px;top:' + top + ';left:50%;margin:-110px 0 0 -75px;z-index: 9999;color:#0099cc;font-size:12px;width:150px;line-height:17px; height:150px;position:absolute;display: flex;flex-direction: column;align-items: center;');
            var body = document.getElementsByTagName("body")[0];
            body.appendChild(loadingDiv);
            loadingDiv.innerHTML = `
        <svg version="1.1" id="L1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 85" enable-background="new 0 0 100 100" xml:space="preserve">
        <circle fill="none" stroke="#fff" stroke-width="4" stroke-miterlimit="15" stroke-dasharray="14.2472,14.2472" cx="50" cy="50" r="27" >
          <animateTransform 
             attributeName="transform" 
             attributeType="XML" 
             type="rotate"
             dur="5s" 
             from="0 50 50"
             to="360 50 50" 
             repeatCount="indefinite" />
      </circle>
      <circle fill="none" stroke="#fff" stroke-width="1" stroke-miterlimit="10" stroke-dasharray="10,10" cx="50" cy="50" r="19">
          <animateTransform 
             attributeName="transform" 
             attributeType="XML" 
             type="rotate"
             dur="5s" 
             from="0 50 50"
             to="-360 50 50" 
             repeatCount="indefinite" />
      </circle>
      <g fill="#fff">
      <rect x="35" y="35" width="3" height="20">
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.1"/>
      </rect>
      <rect x="42.5" y="35" width="3" height="20" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.2"/>
      </rect>
      <rect x="50" y="35" width="3" height="20" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.3"/>
      </rect>
      <rect x="56.5" y="35" width="3" height="20" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5"  
           repeatCount="indefinite" 
           begin="0.4"/>
      </rect>
      <rect x="62.5" y="35" width="3" height="20" >
        <animateTransform 
           attributeName="transform" 
           dur="1s" 
           type="translate" 
           values="0 5 ; 0 -5; 0 5" 
           repeatCount="indefinite" 
           begin="0.5"/>
      </rect>
      </g>
    </svg>
    <span id="loading-div-text" style="font-size: 16px;">Loading...</span>`;

            this._loadingDiv = document.getElementById('loadingDiv');
            this._loadingSpan = document.getElementById('loading-div-text');
        }
        this._loadingSpan.innerHTML = txt
        this._loadingDiv.style.display = 'flex'
    }
    //关闭Loading
    hide() {
        this.hideShadow();
        if (this._loadingDiv) {
            this._loadingDiv.style.display = 'none'
        }

    }
}

export const loading = new Loading()