<template>
	<div id="app">
		<van-popup v-model="showMenu" :style="{ height: '100%', width: '60%', background: 'transparent' }" position="right"
			@click="showMenu = false" @close="closeMenu">
			<div class="menu-body p-all">

				<div class="language d-around-column m-top">
					<div class="d-center-row" :class="[lang == 'zh-CN'?'active':'']">
						<span @click="changeLanguage(1)">简体中文</span>
					</div>
					<div class="d-center-row" :class="[lang == 'en-US'?'active':'']">
						<span @click="changeLanguage(2)">English</span>
					</div>
					<div class="d-center-row" :class="[lang == 'kr'?'active':'']">
						<span @click="changeLanguage(3)">한국어</span>
					</div>
				</div>
			</div>
		</van-popup>
		<div class="view-page max-bg">
			<template v-if="showTabs">
				<div class="top-dis"></div>
				<div class="the-top-box max-bg">
					<div class="the-nav-box d-space-row p-left-right-10">
						<!-- <div class="d-space-row "> -->
						<!-- <div class="account d-center-row m-right" @click="onConnect">
								<span>{{linkWallet}}</span>
							</div> -->
						<div class="dis-left"></div>
						<div class="center-logo d-center-row">
							<img class="arrow-icon" src="../src/assets/image/arrow-1.png" alt="">
							<img class="arc-logo m-left-right" src="../src/assets/image/logo.png" alt="">
							<img class="arrow-icon" src="../src/assets/image/arrow-2.png" alt="">
						</div>
						<div class="right-icon m-right-10" @click="showMenu = true">
							<img src="./assets/image/lang-icon.png" alt="" />
						</div>

						<!-- </div> -->
					</div>
				</div>
			</template>
			<keep-alive :include="aliveView">
				<router-view />
			</keep-alive>
			<template v-if="showTabs">
				<div class="dis-bottom"></div>
				<div class="tabs-list d-space-row">
					<div class="tab-item max-bg d-center-row" :class="[active === index ? 'active' :'']"
						v-for="(item,index) in tabList" @click="goPage(item)">
						<span v-if="lang == 'zh-CN'">{{item.tabTitle}}</span>
						<span v-if="lang == 'en-US'">{{item.enTitle}}</span>
						<span v-if="lang == 'kr'">{{item.krTitle}}</span>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapMutations
	} from "vuex";
	export default {
		name: "App",
		data() {
			return {
				aliveView: ["home"],
				active: 0,
				showMenu: false,

				isShowLang: false,

			};
		},
		created() {
			// console.log(this.lang);
			this.onConnect();
			this.changeLang(localStorage.getItem('lang'))
		},
		computed: {
			...mapState(["tabList", "showTabs", "showBottom"]),
			...mapGetters(['address', 'lang']),
			linkWallet() {
				if (this.address) {
					return this.$dapp.ellipseAddress(this.address, 5)
				}
				return this.$t('app.connect')
			}
		},
		watch: {
			"$route.name": function(newVal, oldVal) {
				if (oldVal !== null) {
					// console.log("清理钱包等待钩子队列");
					// 路由变化，清除等待钱包初始化的钩子队列
					this.$dapp.clearAddressChanged();
					this.$dapp.clearWaiting();
				}
			},
			"$route.path": function(newVal) {
				this.tabList.forEach((tab, index) => {
					if (tab.router === newVal) {
						this.active = index;
						// console.log(this.active);
						this.changeTab(tab.router);
					}
				});
			},
		},
		methods: {
			...mapMutations(["changeTab", "changeLang"]),
			onClickLeft() {
				this.showMenu = true;
			},
			goPage(tab) {
				if (tab.curr) {
					return;
				}
				if (!tab.open) {
					this.$toast(this.$t("app.waiting"));
					return;
				}
				this.showMenu = false;
				this.$router.push(tab.router);
			},
			closeMenu() {
				this.isShowLang = false
			},
			changeLanguage(index) {
				if (index == 1) {
					localStorage.setItem("lang", "zh-CN");
					this._i18n.locale = "zh-CN";
				} else if (index == 2) {
					localStorage.setItem("lang", "en-US");
					this._i18n.locale = "en-US";
				} else if (index == 3) {
					localStorage.setItem("lang", "kr");
					this._i18n.locale = "kr";
				}

				this.changeLang(this._i18n.locale);
				this.isShowLang = false
			},
			async onConnect() {
				this.$dapp.onConnect()
			},
		},
	};
</script>

<style lang="scss">
	@import "../common.scss";

	#app {
		font-family: PingFang-SC-Medium;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		font-size: 28px;
		background: #ffffff;
		color: #fff;

		.menu-header {
			width: 100%;
			height: 96px;
			background: #2e2a4e;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.back-icon {
				width: 80px;
				padding-left: 33px;
				text-align: left;

				img {
					width: 36px;
					vertical-align: middle;
				}
			}

			.logo {
				flex: 1;
				padding-right: 52px;
				text-align: right;

				img {
					width: 138px;
					vertical-align: middle;
				}
			}
		}

		.van-nav-bar__content {
			background-color: #201d42;
		}

		.menu-body {
			width: 203px;
			height: 255px;
			background: #0158007f;
			border: 2px solid #40ff00;
			box-shadow: 0px 0px 29px 0px rgba(9, 9, 9, 0.72);
			border-radius: 10px;
			position: absolute;
			top: 110px;
			right: 20px;

			.menu-list {
				.menu-item {
					font-size: 30px;
					font-weight: bold;
					height: 100px;
					line-height: 70px;
					color: #ffffff;
				}
			}

			.language {
				width: 100%;
				height: 187px;
				border-radius: 10px;
				margin: 0 auto;
				position: relative;
				color: #94A2BE;

				.active {
					color: #fff;
				}

				img {
					width: 46px;
					height: 46px;
					margin-right: 30px;
				}

				.select-lang-box {
					position: absolute;
					bottom: -290px;
					width: 292px;
					height: 279px;
					background: #2D305B;
					border-radius: 10px;
					box-sizing: border-box;
					padding: 50px 0;
				}
			}
		}

		.left-icon {
			img {
				width: 35px;
				vertical-align: middle;
			}
		}


		.account {
			width: 219px;
			height: 62px;
			background: #000000;
			color: #C1E5FF;
			border-radius: 31px;
		}

		.view-page {
			overflow-y: scroll;
			width: 100%;
			height: 100vh;
			background: url(../src/assets/image/main-bg.png);
		}

		.top-dis {
			width: 100%;
			height: 96px;
		}

		.the-top-box {
			position: fixed;
			top: 0;
			width: 100%;
			height: 119px;
			background: url('../src/assets/image/nav-bg.png');
			z-index: 999;

			.the-nav-box {
				width: 100%;
				height: 100%;
				padding-bottom: 20px;

				.dis-left {
					width: 50px;
					height: 100%;
				}

				.center-logo {
					.arrow-icon {
						width: 25px;
						height: 19px;
					}

					.arc-logo {
						width: 148px;
						height: 64px;
					}
				}

				.right-icon {
					img {
						width: 50px;
						height: 50px;
					}
				}
			}
		}

		.van-nav-bar .van-icon {
			color: #323233;
		}

		.van-tabbar {
			box-shadow: 0px 0px 13px 0px rgba(74, 74, 74, 0.17);
		}

		.connect {
			width: 193px;
			height: 50px;
			border: 1px solid #6db9ff;
			border-radius: 8px;
			color: #6db9ff;
		}

		.dis-bottom {
			width: 100%;
			height: 90px;
		}

		.tabs-list {
			width: 100%;
			height: 90px;
			background: #122209;
			z-index: 9;
			position: fixed;
			left: 0;
			bottom: 0;

			.tab-item {
				width: 150px;
				height: 89px;
				background: url('../src/assets/image/tab-bg.png');
				font-size: 30px;
				color: #6EA336;
				text-align: center;
				&.active {
					color: #fff;
					background: url('../src/assets/image/tab-bg-active.png');
				}
			}
		}

	}
</style>