<template>
	<div class="my-header-item-container">
		<div class="bottom-box d-space-column">
			<!-- 底部背景 -->
			<div class="bottom-border-bg max-bg"></div>
			<!-- 侧边 -->
			<div class="bottom-side-border p-all-10" :style="{height:height+'px'}"></div>
			<div class="header-title d-space-row m-bottom">
				<div  @click="$router.go(-1)">
					<img src="../../assets/image/back-icon.png" alt="">
				</div>
				<div class="d-center-row flex-1">
					<span class="f-42">{{title}}</span>
				</div>
				<div></div>
			</div>
			<!-- 插槽 -->
			<slot name="content"></slot>
			<div class="bottom-border max-bg"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: ''
			},
			height: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {

			};
		},
		methods: {

		}

	}
</script>

<style lang="scss" scoped>
	.my-header-item-container {
		.bottom-box {
			width: 100%;
			position: relative;

			.bottom-border-bg {
				width: 100%;
				height: 280px;
				background: url('../../assets/image/bottom-border-bg.png');
				position: absolute;
				top: 0;
				left: 0;
			}

			.bottom-side-border {
				width: 100%;
				// height: 1102px;
				border-left: 2px solid #4BB005;
				border-right: 2px solid #4BB005;
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.header-title {
				width: 100%;
				height: 116px;
				box-sizing: border-box;
				padding-left: 50px;
				padding-right: 100px;
				position: relative;
				z-index: 9;
				img {
					width: 64px;
					height: 64px;
				}
			}

			.bottom-border {
				width: 100%;
				height: 2px;
				background: url('../../assets/image/bottom-border.png');
			}
		}
	}
</style>