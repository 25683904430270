export default {
	user: {
		id: 0,
		address: '',
		parent: null,
	},
	showTabs: true, //地步tab栏是否显示
	lang: localStorage.getItem('lang') || 'en-US',
	tabList: [{
			tabTitle: "首页",
			enTitle: 'Home',
			krTitle: '첫 페이지',
			curr: true,
			open: true,
			router: "/index",
		},
		{
			tabTitle: "质押",
			enTitle: 'Pledge',
			krTitle: '질권',
			curr: false,
			open: true,
			router: "/pledge",
		},
		{
			tabTitle: "LP质押",
			enTitle: 'LP Pledge',
			krTitle: 'LP 질권',
			curr: false,
			open: true,
			router: "/lp",
		},
		{
			tabTitle: "盲盒",
			enTitle: 'Blind Box',
			krTitle: '블라인드 박스',
			curr: false,
			open: true,
			router: "/blindBox",
		},
		{
			tabTitle: "我的",
			enTitle: 'My',
			krTitle: '나의',
			curr: false,
			open: true,
			router: "/my",
		},
	],
}