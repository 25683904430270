import request from '@/utils/request'

//获取账户信息
export const  accountInfo  = () => request.get('account/info')

// 绑定上级
export const bindParent = data => request.get('account/bindParent', data)

// 余额
export const balance = data => request.get('account/balance', data)

// 余额日志
export const balanceLogs = data => request.get('account/balanceLogs', data)

// 获取提现签名
export const withdrawSign = (data) => request.get('withdraw/withdrawSign', data)

// 更新提现交易hash
export const updateTxHash = (data) => request.post('withdraw/updateTxHash', data)

// 判断网络状态
export const networkStatus = (data) => request.get('account/networkStatus', data)

export const groupIncome = (data) => request.get('account/groupIncome', data)

export const groupParams = () => request.get('account/groupParams')

export const children = () => request.get('account/children')

export const  sign  = () => request.get('account/sign')

export const  signInfo  = () => request.get('account/signInfo')


