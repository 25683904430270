
import { ERC20 } from './ERC20'
import abi from './abis/Pair'
import { getApprovalDigest } from './lib/erc2612'
import { fromRpcSig } from 'ethereumjs-util'
export class Pair extends ERC20 {

  constructor(address) {
    super(address, abi)
  }

  async getReserves() {
    const { _reserve0, _reserve1, _blockTimestampLast } = await this.callContract(
      'getReserves',
      []
    )
    return [this.fromToken(_reserve0.toString(), 18), this.fromToken(_reserve1.toString(), 18), _blockTimestampLast]
  }

  async name() {
    return await this.callContract("name", [])
  }

  async nonces(account = this.dapp.currentAddress) {
    const nonce =  await this.callContract("nonces", [account])
    return nonce.toNumber()
  }

  async chainId() {
    const chainId = await this.dapp.web3.eth.net.getId();
    return chainId.toString();
  }

  async domainSeparator() {
    return await this.callContract("DOMAIN_SEPARATOR", [])
  }

  _deadline(deadline) {
    return Math.floor(Date.now() / 1000) + 60 * Number(deadline)
  }

  // 链下授权，使用普通签名
  async approvalDigest(spender, deadline = 1) {
    const nonce = await this.nonces()
    const amount = this.dapp.MAX_APPROVE
    const _deadline = this._deadline(deadline)
    const msg = await getApprovalDigest(this, this.dapp.currentAddress, spender, amount, nonce, _deadline)
    const sign = await this.dapp.signMessage(msg, false)
    const { v, r, s } = fromRpcSig(sign);
    return [this.currentAddress, spender, amount, _deadline, v, r, s]
  }

  // 链下授权，通过EIP712签名
  async approvalPermit(spender, deadline = 1) {
    const amount = this.dapp.MAX_APPROVE
    const _deadline = this._deadline(deadline)
    const msgParams = {
      domain: {
        chainId: await this.chainId(),
        name: await this.name(),
        verifyingContract: this.address,
        version: '1',
      },
      message: {
        owner: this.dapp.currentAddress,
        spender,
        value: amount,
        nonce: await this.nonces(),
        deadline: _deadline
      },
      primaryType: 'Permit',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Permit: [
          { name: 'owner', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'deadline', type: 'uint256' }
        ],
      },
    };
    const sign = await this.dapp.signTypedDataV4(msgParams)
    const { v, r, s } = fromRpcSig(sign);
    return [this.currentAddress, spender, amount, _deadline, v, r, s]
  }

  async permit(owner, spender, value, deadline, v, r, s) {
    return await this.sendContract(
      'permit',
      [owner, spender, value, deadline, v, r, s]
    )
  }
}