import axios from 'axios'
import store from '@/store'
import { Toast } from 'vant';
import i18n from '@/language'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_HOST, // api 的 base_url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    // if (store.getters.token) {
    //   config.headers['X-Api-Token'] = store.getters.token
    // }
    if (store.getters.address) {
      config.headers['ACCOUNT-ADDRESS'] = store.getters.address
    }
    if (store.getters.lang) {
      config.headers['Accept-Language'] = store.getters.lang
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const { data: { code, data, msg } } = response
    if (code === 200) {
      return data
    } else {
      //全局提示错误消息
      console.log('error' + msg)
      Toast.fail(msg);
    }
    return Promise.reject(response.data)
  }, error => {
    if (!error.response) {
      Toast.fail(i18n.t('app.serverError'));
      return Promise.reject(error)
    }
    const { response: { data } } = error
    console.log('err' + error)// for debug
    if (data && typeof data.msg !== 'undefined') {
      Toast.fail(data.msg);
    } else {
      //toast
      Toast.fail(i18n.t('app.serverError'));
    }
    return Promise.reject(data ? data : error)
  })


const success = data => {
  return {
    res: data,
    err: null
  }
}

const fail = err => {
  return {
    res: null,
    err
  }
}


/**
* 使用es6中的类，进行简单封装
*/
export default class Request {
  // 使用async ... await
  static async get(url, params = {}) {
    try {
      const res = await service.get(url, { params })
      return success(res)
    } catch (err) {
      return fail(err)
    }
  }

  static async post(url, params = {}) {
    try {
      const res = await service.post(url, params)
      return success(res)
    } catch (err) {
      return fail(err)
    }
  }

  static async put(url, params = {}) {
    try {
      const res = await service.put(url, params)
      return success(res)
    } catch (err) {
      return fail(err)
    }
  }

  static async delete(url, params = {}) {
    try {
      const res = await service.delete(url, { data: params })
      return success(res)
    } catch (err) {
      return fail(err)
    }
  }
}