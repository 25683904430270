
import Web3Modal, { checkInjectedProviders } from 'web3modal';
import { providerOptions } from './providerOptions'
import WalletClient from './WalletClient';
import { handleError } from './error'


class Wallet {

    web3Modal = null

    _zeroAddress = '0x0000000000000000000000000000000000000000'

    _currentAccount = null

    _ethereum = null

    _contracts = {}

    _client = null

    constructor() {
        this._chainId = undefined
        this._currentAccount = undefined
        this._client = new WalletClient(this._connect.bind(this), this.closeApp.bind(this))
    }

    setChainId(chainId) {
        this._client.chainId = chainId
    }

    get address() {
        return this._client.address
    }

    get client() {
        return this._client
    }

    async waitingTp() {
        return new Promise((resolve, reject) => {
            const { injectedAvailable } = checkInjectedProviders()
            if (!injectedAvailable) {
                let count = 0;
                const timer = setInterval(() => {
                    count++;
                    const { injectedAvailable } = checkInjectedProviders()
                    if (injectedAvailable) {
                        clearInterval(timer)
                        resolve()
                    }
                    if (count > 10) {
                        clearInterval(timer)
                        resolve()
                        // reject("tokenpocket error")
                    }
                }, 500)
            } else {
                resolve()
            }
        })
    }

    async _connect() {
        if (localStorage.getItem('walletconnect')) {
            // walletconnect app端意外退出断开，无法继续重新连接，直接清除
            localStorage.removeItem('walletconnect')
        }
        // 兼容TP钱包可能出现未注入web3错误
        await this.waitingTp()
        this.web3Modal = new Web3Modal({
            theme: 'dark',
            network: "binance", // optional
            cacheProvider: false, // optional
            providerOptions // required
        });
        await this.web3Modal.clearCachedProvider();
        // await this.web3Modal.toggleModal();
        this._ethereum = await this.web3Modal.connect();
        this._client.ethereum = this._ethereum
    }

    async onConnect() {
        try {
            await this._client.onConnect()
            return null
        } catch (err) {
            return handleError(err)
        }
    }

    async closeApp() {
        if (this.web3Modal && this.web3Modal.currentProvider && this.web3Modal.currentProvider.close) {
            await this.web3Modal.currentProvider.close();
        }
        await this.web3Modal.clearCachedProvider();
    }

}
const instance = new Wallet()

export const Client = instance.client

export default instance