import WalletConnectProvider from '@walletconnect/web3-provider';
import networks from './networks'


const rpcs = {}
networks.forEach(item => {
    rpcs[item.networkId] = item.url
})

export const providerOptions = {
    /* See Provider Options Section */
    // https://docs.walletconnect.org/
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: '774b1e4252de48c3997d66ac5f5078d8', // TODO infuraId
            rpc: rpcs,
        },
    },
};