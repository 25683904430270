
import { verifyTypedData } from 'ethers/lib/utils'


/**
 * person sign，
 * @param {Object} signData examle  { type: '333', time: '2022-01-01' }
 * @returns 
 */
export const getSignTypedData = (signData) => {

    if (Object.prototype.toString.call(signData) !== '[object Object]') {
        throw Error('参数不正确，请传入要签名对象')
    }
    const message = {}
    const data = []
    Object.keys(signData).sort().forEach(key => {
        message[key] = String(signData[key])
        data.push({
            name: key,
            type: 'string'
        })
    })
    return [
        {
            name: 'DAPP',
            version: '1',
        },
        {
            Data: data
        },
        { ...message }
    ]
}

/**
 * perion sign 验签
 * @param {*} signature 
 * @param {*} signData 
 * @param {*} address 
 * @returns 
 */
export const verifySignature = (signature, signData, address) => {
    return verifyTypedData(
        ...getSignTypedData(signData), signature
    ).toLowerCase() === address.toLowerCase()
}
