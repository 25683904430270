

export const handleError = (error) => {
    if (!error) {
        console.error("other RpcError", "local handleRpcError");
        return 'other RpcError';
    }
    if (error.data && error.data.message) {
        return error.data.message;
    }

    if (error.code === -32603) {
        return "Internal JSON-RPC error";
    }

    if (error.code === 4001) {
        return "Transaction rejected!";
    }
    if (error.message) {
        return error.message;
    }
    if (error === 'cancel') {
        // 移动端tp钱包
        return 'user cancle transaction'
    }
    if (error === 'Modal closed by user') {
        return error
    }
    console.log(error)
    return "Unexpected interruption of transaction"

}