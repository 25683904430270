module.exports = {
	app: {
		waiting: '敬请期待',
		loading: '加载中...',
		finished: '没有更多了',
		connect: '连接钱包',
		serverError: '服务器繁忙，请稍后重试',
		master: "主",
		sftz: "身份拓展",
		contact: "联系我们",
		success: "操作成功",
		opeSuccess: "操作成功",
		fail: "操作失败",
	},
	index: {
		title:'关于LELE',
		content: "LELE是Arbitrum生态系统中最受欢迎的meme币，同时是通缩的。它被空投给那些领取了ARB空投的人，LELE生态系统即将爆发，未来即将完善ARB链上的不足，丰富生态。",
		price: "LELE价格",
		production: "总产出",
		destroy: "总销毁",
		pay: "组合支付",
		myNode: "我的节点",
		node1: "初级节点",
		node2: "中级节点",
		node3: "高级节点",
		node4: "超级节点",
		buyNow: "立即购买",
		under:"待释放",
		released:"已释放",
		daily:"每日",
		none:"暂无节点",
		signReward:"签到奖励",
		signNow:"立即签到",
		signed:"已签到"
	},
	pledge:{
		pledge:"质押",
		day:"天",
		balance:"LELE余额",
		placeHolder:"请输入数量",
		pledgeNow:"立即质押",
		none:"没有更多了",
		myPledge:"我的质押",
		pledgeAmount:"质押数量",
		days:"天数",
		daily:"每日收益",
		payType:"请选择支付方式",
		amountErr:"质押数量不足",
		refund:"赎回",
		lpBalance:'LP 余额'
	},
	pool:{
		title:'矿池',
		tgsy:'推广收益'
	},
	my:{
		level1:'玄武',
		level2:'朱雀',
		level3:'白虎',
		level4:'青龙',
		title:'我的',
		tx:'提现',
		tjyj:'推荐有奖',
		wdtd:'我的团队',
		aqzx:'矿池',
		tcdl:'退出登录',
		
		record:'记录',
		
		yqlj:'邀请链接',
		fzlj:'复制链接',
		tips:'邀请好友越多收益越多',
		
		zrs:'总团队人数',
		zyj:'总团队业绩',
		xyj:'小区业绩',
		wdzt:'我的直推',
		rq:'日期',
		dz:'地址',
		lx:'类型',
		
		tbbz:'提币币种',
		fee:'手续费',
		tbsl:'提币数量',
		place:'请输入提币数量',
		qrtx:'确认提现',
		
		type1:'提现扣除',
		type2:'提现过期',
		type3:'节点静态奖励',
		type4:'节点动态奖励',
		type5:'质押扣除',
		type6:'质押收益',
		type7:'质押本金',
		type8:'盲盒',
		type9:'手续费',
		type10:'权益分红',
		type11:'权益分红(平级)',
		type12:'LP质押收益',
		type13:'签到奖励',
		type14:'LP质押直推收益',
		type15:'LP质押间推收益',
		yx:'有效',
		wx:'无效'
	},
	draw:{
		blind:'盲盒',
		open:'开启盲盒',
		records:'记录',
		no:'未中奖',
		get:'获得'
	}
	
}
