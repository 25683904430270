import { Contract } from './Contract'

import abi from './abis/Router'

export class Router extends Contract {

  _tokenToken = null
  _usdtToken = null

  constructor(address, token, usdt) {
    super(address, abi)
    this._tokenToken = token
    this._usdtToken = usdt
  }

  _deadline(deadline) {
    return Math.floor(Date.now() / 1000) + 60 * Number(deadline)
  }

  _getPath(swapToken) {
    let path
    if (swapToken) {
      // usdt兑换token
      path = [this._usdtToken, this._tokenToken]
    } else {
      // token兑换usdt
      path = [this._tokenToken, this._usdtToken]
    }
    return path
  }

  async addLiquidity(tokenAmount, usdtAmount, tokenMin, usdtMin, deadline = 1) {
    return await this.sendContract(
      'addLiquidity',
      [
        this._tokenToken,
        this._usdtToken,
        this.toToken(tokenAmount, 18),
        this.toToken(usdtAmount, 18),
        this.toToken(tokenMin, 18),
        this.toToken(usdtMin, 18),
        this.currentAddress,
        this._deadline(deadline)
      ]
    )
  }

  async removeLiquidity(liquidity, tokenMin, usdtMin, deadline = 1) {
    return await this.sendContract(
      'removeLiquidity',
      [
        this._tokenToken,
        this._usdtToken,
        this.toToken(liquidity, 18),
        this.toToken(tokenMin, 18),
        this.toToken(usdtMin, 18),
        this.currentAddress,
        this._deadline(deadline)
      ]
    )
  }

  /**
   * 通过输入金额，输出不确定金额 (也可以理解为卖， 如卖掉100个UNI TOKEN,可以获得未知WETH)
   * @param {Number} amountIn 实际输入金额
   * @param {Number} amountOutMin 最小输出 （如果到打包你的交易时，实际输出小于该金额，交易失败！）
   * @param {Boolean} swapToken 是否兑换ABRD
   * @param {Number} deadline 
   */
  async swapExactTokensForTokens(amountIn, amountOutMin, swapToken, deadline = 1) {
    return await this.sendContract(
      'swapExactTokensForTokens',
      [
        this.toToken(amountIn, 18),
        this.toToken(amountOutMin, 18),
        this._getPath(swapToken),
        this.currentAddress,
        this._deadline(deadline)
      ]
    )
  }

  /**
   * 需要获取精确的输出，输入不确定金额（也可以理解买，如购买100个UNI TOKEN，需要未知weth）
   * @param {Number} amountOut 期望输出金额
   * @param {Number} amountInMax 最大输入  (如果到你打包的交易时，如果实际需要输入的金额大于该金额，交易失败!)
   * @param {Boolean} swapToken 是否兑换ABRD
   * @param {Number} deadline 
   */
  async swapTokensForExactTokens(amountOut, amountInMax, swapToken, deadline = 1) {
    return await this.sendContract(
      'swapTokensForExactTokens',
      [
        this.toToken(amountOut, 18),
        this.toToken(amountInMax, 18),
        this._getPath(swapToken),
        this.currentAddress,
        this._deadline(deadline)
      ]
    )
  }

  /**
   * 通过输入金额，输出不确定金额
   * @param {Number} amountIn 实际输入金额
   * @param {Number} amountOutMin 最小输出
   * @param {Boolean} swapToken 是否兑换Token
   * @param {Number} deadline 
   */
  async swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, swapToken, deadline = 1) {
    console.log(this)
    return await this.sendContract(
      'swapExactTokensForTokensSupportingFeeOnTransferTokens',
      [
        this.toToken(amountIn, 18),
        this.toToken(amountOutMin, 18),
        this._getPath(swapToken),
        this.currentAddress,
        this._deadline(deadline)
      ]
    )
  }

  /**
   * 根据兑换路径和输入数量，计算得到兑换路径中每个交易对的输出数量
   * @param {Number} amountIn 
   * @param {Boolean} swapToken 是否兑换ABRD
   */
  async getAmountsOut(amountIn, swapToken) {
    const path = this._getPath(swapToken)
    const amounts = await this.callContract(
      'getAmountsOut',
      [this.toToken(amountIn, 18), path]
    )
    return this.fromToken(amounts[path.length - 1].toString(), 18)
  }

  /**
   * 根据兑换路径和输出数量，计算得到兑换路径中每个交易对的输入数量
   * @param {Number} amountOut 
   * @param {Boolean} swapToken 是否兑换ABRD
   */
  async getAmountsIn(amountOut, swapToken) {
    const path = this._getPath(swapToken)
    const amounts = await this.callContract(
      'getAmountsIn',
      [this.toToken(amountOut, 18), path]
    )
    return this.fromToken(amounts[0].toString(), 18)
  }
}