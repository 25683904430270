
import { Contract } from './Contract'
import baseApi from './abis/InvestShop'
export class InvestShop extends Contract {

  constructor(address) {
    super(address, baseApi)
  }


  async removeInvest({ user, id, arcAmount, deadBlock,nonce, v, r, s }) {
    return await this.sendContract(
      'removeInvest',
      [user, id, arcAmount, deadBlock,nonce, v, r, s]
    )
  }

  async addInvest(arcAmount) {
    return await this.sendContract(
      'addInvest',
      [this.toToken(arcAmount, 18)]
    )
  }

  async tokenIn() {
    return await this.callContract(
      'tokenIn',
      []
    )
  }

  async setTokenIn(address) {
    return await this.sendContract(
      'setTokenIn',
      [address]
    )
  }

}