import { Toast } from 'vant';
import { utils } from 'ethers'
import { Client } from './Wallet'
import { toToken } from './utils'
import { handleError } from './error'

import { loading } from './Loading'

const sendTxData = async (txData) => {
    try {
        loading.show()
        const gas = await Client.estimateGas({ ...txData })
        console.log('预估gas：', Number(gas))
        txData.gasLimit = utils.hexValue(gas * 2)
    } catch (err) {
        console.log(err)
        const msg = handleError(err)
        loading.hide()
        Toast.fail(msg)
        return [msg, null]
    }
    try {
        const txHash = await Client.sendTransaction(txData)
        return await getTransactionReceipt(txHash)
    } catch (err) {
        loading.hide()
        return [handleError(err), null]
    }
}

export const transfer = async (to, value) => {
    const txData = {
        from: Client.address,
        to,
        value: utils.hexValue(Number(toToken(value, 18))),
    }
    return await sendTxData(txData)
}


export const sendTransaction = async (txParams, value = 0) => {
    const txData = {
        // from: Wallet.address,
        value: utils.hexValue(Number(toToken(value, 18))),
        ...txParams,
        // gasPrice: utils.hexValue(5100000000),
    }
    // console.log(txData)
    return await sendTxData(txData)
}

export const getTransactionReceipt = async (txHash) => {
    const result = await new Promise((resolve) => {
        setTimeout(async () => {
            const receipt = await Client.getTransactionReceipt(txHash)
            if (receipt) {
                if (Number(receipt.status) === 1) {
                    // 成功
                    resolve('success')
                } else {
                    resolve('error')
                }
            } else {
                // 无返回
                resolve('null')
            }
        }, 2000)
    })
    if (result === 'null') {
        return await getTransactionReceipt(txHash)
    }
    loading.hide()
    if (result === 'success') {
        Toast.success('操作成功');
    } else {
        Toast.fail('操作失败')
    }
    return [result === 'error' ? '交易失败' : null, txHash]
}