
import { Contract } from './Contract'
import baseApi from './abis/InvestMoreShop'
export class InvestMoreShop extends Contract {

  constructor(address) {
    super(address, baseApi)
  }


  async removeInvest({ user, id, arcAmount, deadBlock,nonce, v, r, s }) {
    return await this.sendContract(
      'removeInvest',
      [user, id, arcAmount, deadBlock,nonce, v, r, s]
    )
  }

  async addInvest(arcAmount,days) {
    return await this.sendContract(
      'addInvest',
      [this.toToken(arcAmount, 18),days]
    )
  }

}