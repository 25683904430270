
import { Contract } from './Contract'
import erc20Abi from './abis/ERC20'
import { ZERO_ADDRESS } from './lib/constants'

export class ERC20 extends Contract {

  decimals = 18

  constructor(address, abi = null) {
    super(address, abi ? abi : erc20Abi)
  }

  async transfer(address, amount) {
    return await this.sendContract(
      'transfer',
      [address, this.toToken(amount, this.decimals)]
    )
  }

  // 授权
  async approve(address, amount = this.MAX_APPROVE) {
    try {
      let allowance = await this.allowance(await this.syncCurrentAddress(), address)
      allowance = Number(allowance)
      if (amount !== this.MAX_APPROVE) {
        if (allowance >= Number(amount)) {
          return [null, null]
        }
      }
      if (allowance > 100000000) {
        return [null, null]
      }
      return await this.sendContract(
        'approve',
        [address, amount === this.MAX_APPROVE ? amount : this.toToken(amount, this.decimals)]
      )
    } catch (err) {
      return [err.message, null]
    }
  }

  // 获取余额
  async balanceOf(address = null) {
    try {
      const balance = await this.callContract(
        'balanceOf',
        [address || await this.syncCurrentAddress()]
      )
      return this.fromToken(balance.toString(), this.decimals)
    } catch (err) {
      console.log(err)
      return '0'
    }
  }

  async usdtBalanceOf(address = null) {
    try {
      const balance = await this.callContract(
        'balanceOf',
        [address || await this.syncCurrentAddress()]
      )
      return this.fromToken(balance.toString(), 6)
    } catch (err) {
      console.log(err)
      return '0'
    }
  }

  async totalSupply() {
    const totalSupply = await this.callContract(
      'totalSupply',
      []
    )
    return this.fromToken(totalSupply.toString(), this.decimals)
  }

  // 获取授权额度
  async allowance(owner, spender) {
    const allownace = await this.callContract(
      'allowance',
      [owner, spender]
    )
    return this.fromToken(allownace.toString(), this.decimals)
  }
}