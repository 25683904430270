export default {
	//更新用户信息
	updateUser(state, user) {
		state.user = {
			...state.user,
			...user
		}
	},
	// 切换主页tab栏
	changeTab(state, tab) {
		const tabs = state.tabList.map(item => {
			if (item.router === tab) {
				return { ...item, curr: true }
			} else {
				return { ...item, curr: false }
			}
		})
		state.tabList = tabs
	},
	//切换底部tab栏是否显示
	toggleTabs(state, isShow) {
		state.showTabs = isShow
	},
	changeLang(state, lang) {
		if (lang) {
			state.lang = lang
		}  else {
			state.lang = 'en-US'
			localStorage.setItem('lang','en-US')
		}
	}
}