module.exports = {
	app: {
		waiting: 'Please look forward to it',
		loading: 'Loading...',
		finished: 'not more',
		connect: 'Connect Wallet',
		serverError: 'service error,please try again later',
		master: "Master",
		sftz: "Identity expansion",
		contact: "Contact us",
		success: "success",
		fail: "fail",
	},
	index: {
		title:'About LELE',
		content: "LELE is the most popular meme coin in the Arbitrum ecosystem and is deflationary. It was airdropped to those who received the ARB airdrop. The LELE ecosystem is about to explode. In the future, the deficiencies in the ARB chain will be improved and the ecosystem will be enriched.",
		price: "LELE price",
		production: "Total output",
		destroy: "Total destruction",
		pay: "Combined Payment",
		myNode: "My Node",
		node1: "Primary node",
		node2: "Intermediate node",
		node3: "Advanced Node",
		node4: "Super Node",
		buyNow: "Buy Now",
		under: "To be released",
		released: "Released",
		daily: 'Every day',
		none: "There are currently no nodes",
		signReward:"Sign-in Reward",
		signNow:"Sign in",
		signed:"Signed in"
	},
	pledge: {
		pledge: "Pledge",
		day: 'Day',
		balance: "LELE balance",
		placeHolder: "Please enter the quantity",
		pledgeNow: "Pledge Now",
		none: "There's no more",
		myPledge: "My Pledge",
		pledgeAmount: "Pledged Quantity",
		days: "Days",
		daily: "Daily Revenue",
		payType: "Please select payment type",
		amountErr: "Pledge amount less than",
		refund: "redeem",
		lpBalance:'LP balance'
	},
	pool: {
		title: 'Mine Pool',
		tgsy: 'Promotion revenue'
	},
	my: {
		level1:'Black Tortoise',
		level2:'Vermillion Bird',
		level3:'White Tiger',
		level4:'Azure Dragon',
		title: 'Mine',
		tx: 'Withdrawal',
		tjyj: 'Recommendation rewards',
		wdtd: 'My team',
		aqzx: 'Pool',
		tcdl: 'Log out of login',

		record: 'Record',

		yqlj: 'Invitation link',
		fzlj: 'Copy Link',
		tips: 'The more friends you invite, the more profits you earn.',

		zrs: 'Total number of team members',
		zyj: 'Overall team performance',
		xyj:'Other team performance',
		wdzt: 'My direct push',
		rq: 'Date',
		dz: 'Address',
		lx: 'Type',

		tbbz: 'Withdrawal currency',
		fee: 'fee',
		tbsl: 'Withdrawal quantity',
		place: 'Please enter the withdrawal quantity',
		qrtx: 'Confirm withdrawal',

		type1: 'Withdrawal deduction',
		type2: 'Withdrawal expired',
		type3: 'Node static rewards',
		type4: 'Node dynamic rewards',
		type5: 'Pledge deduction',
		type6: 'Pledge yield',
		type7: 'Pledge principal',
		type8: 'Blind box',
		type9: 'Handling Fee',
		type10: 'Equity Dividend',
		type11: 'Equity Dividend (Equal Level)',
		type12:'LP Pledge Income',
		type13:'Sign-in Reward',
		type14:'Direct Referral Earnings from LP Pledging',
		type15:'Indirect Referral Earnings from LP Pledging',
		yx: 'Valid',
		wx: 'Invalid'
	},
	draw: {
		blind: 'Blind box',
		open: 'Open blind box',
		records: 'Records',
		no: 'not win',
		get: 'Got'
	}
}